import React, {useEffect} from "react";
import {Assets} from "../../components/assets/Assets";
import {Description} from "../../components/description/Description";
import {Donation} from "../../components/donation/Donation";

import css from "./Portfolio.module.css"
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {selectAssets} from "../../components/assets/assetsSlice";
import {useNavigate, useParams} from "react-router-dom";
import {fetchAssetsAsync} from "../../state/assetsApi";
import {NotFound} from "../../components/notfound/NotFound";
import {selectAuth} from "../auth/authSlice";


export const Portfolio: React.FC = () => {
    const {userID} = useParams();
    const {id} = useAppSelector(selectAuth);
    const assets = useAppSelector(selectAssets);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let profileId = '';
        if (userID === 'me') {
            if (id === '') {
                navigate('/auth');
            } else {
                profileId = id;
            }
        } else if (userID) {
            profileId = userID;
        }

        dispatch(fetchAssetsAsync((profileId)));
        const interval = setInterval(() => {
            dispatch(fetchAssetsAsync(profileId));
        }, 60000);

        return () => clearInterval(interval);
    }, [dispatch, userID, id, navigate]);

    return <div className={css.page}>
        {!assets.isLoading && !assets.notFound && <div>
            <div className={css.headerContainer}>
                <h1 className={css.header}>Is CRYPTO a good investment?</h1>
                <div className={css.logo}></div>
            </div>
            <div className={css.assets}>
                <Assets/>
            </div>
            <Description/>
            <Donation/>
        </div>}
        {assets.notFound && <NotFound/>}
    </div>
}
