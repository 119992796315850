import {Assets} from "../assets/assetsSlice";
import {createSlice} from "@reduxjs/toolkit";
import {fetchChartAsync} from "../../state/assetsApi";
import {RootState} from "../../state/store";


export interface ChartData {
    points: Assets[]
}


const initialState: ChartData = {
    points: []
}


export const assetsChart = createSlice({
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChartAsync.pending, (state) => {
                // state.status = 'loading';
            })
            .addCase(fetchChartAsync.fulfilled, (state: ChartData , action: {payload: Assets[]}) => {
                // state.status = 'idle';
                const assets = action.payload;
                if (assets.length > 0) {
                    state.points = action.payload.reverse();
                }

            })
            .addCase(fetchChartAsync.rejected, (state) => {
                // state.status = 'failed';
            });
    },
    name: 'chart',
    initialState: initialState
});

export const selectChartPoints = (state: RootState) => state.chart.points
export const assetsChartReducer =  assetsChart.reducer
