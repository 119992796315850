import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit'
import {assetsReducer} from '../components/assets/assetsSlice';
import {assetsChartReducer} from '../components/chart/chartSlice';
import {authReducer, initKeycloak, setAuth, setInitialized} from "../pages/auth/authSlice";

export const store = configureStore({
    reducer: {
        assets: assetsReducer,
        chart: assetsChartReducer,
        auth: authReducer,
    }
})

initKeycloak(
    () => store.dispatch(setInitialized()),
    (kc) => store.dispatch(setAuth(kc))
);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
