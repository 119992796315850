import Keycloak, {KeycloakOnLoad} from "keycloak-js";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../state/store";

let initOptions = {
    url: 'https://auth.crypto.mnck.dev',
    realm: 'crypto',
    clientId: 'crypto-ui',
    onLoad: 'check-sso' as KeycloakOnLoad,
}

let _kc = new Keycloak(initOptions);


export const initKeycloak = (onInitCallback: () => void, onAuthenticatedCallback: (kc: KCAuthPayload) => void) => {
    _kc.init({
        onLoad: initOptions.onLoad,
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    }).then((auth) => {
        if (!auth) {
            console.log("is not authenticated");
        } else {
            onAuthenticatedCallback(
                {token: _kc.idToken, username: _kc.tokenParsed?.preferred_username, id: _kc.tokenParsed?.sub}
            );

            //Token Refresh
            setInterval(() => {
                _kc.updateToken(70).then((refreshed) => {
                    if (refreshed) {
                        onAuthenticatedCallback(
                            {token: _kc.idToken, username: _kc.tokenParsed?.preferred_username, id: _kc.tokenParsed?.sub}
                        );
                        console.log('Token refreshed');
                    } else {
                        // console.log('Token not refreshed, valid for '
                        //     + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                        // setKeyCloak(keycloak);
                        console.log("token refreshed")

                    }
                }).catch(() => {
                    console.log('Failed to refresh token');
                });
            }, 6000)
        }

        onInitCallback();
    }).catch((e) => {
        console.log("Authenticated Failed");
    });
};


interface AuthState {
    isLoggedIn: boolean,
    token: string,
    username: string,
    id: string,
    isInitialized: boolean,
}


const initialState: AuthState = {
    isLoggedIn: false,
    token: "",
    username: "",
    id: "",
    isInitialized: false,
}

interface KCAuthPayload {
    token: string | undefined
    username: string | undefined
    id: string | undefined
}


const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        setAuth: (state: AuthState, action: PayloadAction<KCAuthPayload>) => {
            state.isInitialized = true;
            state.isLoggedIn = !!action.payload.token;
            state.token = action.payload.token ?? "";
            if (action.payload.username) {
                state.username = action.payload.username;
            }

            if (action.payload.id) {
                state.id = action.payload.id;
            }
        },

        setInitialized: (state: AuthState) => {
            state.isInitialized = true;
        }
    },
})


export const login = () => _kc.login();

export const {setAuth, setInitialized} = authenticationSlice.actions
export const authReducer = authenticationSlice.reducer
export const selectAuth = (state: RootState) => state.auth
