import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {useAppSelector} from "../../state/hooks";
import {login, selectAuth} from "./authSlice";

import css from "./Auth.module.css";


export const AuthPage: React.FC = () => {
    const auth = useAppSelector(selectAuth);
    const navigate = useNavigate();
    useEffect(() => {
        if (auth.isLoggedIn) {
           navigate('/' + auth.id);
        }
    }, [auth.isLoggedIn, auth.id, navigate])


    return <div className={css.authForm}>
        <button className={css.loginButton} onClick={login}>Login</button>
    </div>
}
