
const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    } else {
        // @ts-ignore
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'de-DE';
    }
}


const userLanguage = getNavigatorLanguage();

export const asEuro = (value: number) => {
    return new Intl.NumberFormat(userLanguage, { style: 'currency', currency: 'EUR' }).format(value);
}


export const asCrypto = (value: number, currency: string = 'DOT') => {
    return new Intl.NumberFormat(userLanguage, { style: 'currency', currency: currency, maximumFractionDigits: 4 }).format(value);
}
