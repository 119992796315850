import {createAsyncThunk} from '@reduxjs/toolkit';
import {Assets} from "../components/assets/assetsSlice";
import {RootState} from "./store";

export const fetchChartAsync = createAsyncThunk(
    'assets/fetchChart',
    async () => await fetch('/api/v1/crypto/assets/chart')
        .then(response => response.json()),
);


export const fetchAssetsAsync = createAsyncThunk<Assets, string, {state: RootState}>(
    'assets/fetchAssets',
    async (userID: string, thunkAPI) => {
        const token = thunkAPI.getState().auth.token;
        const headers = new Headers();
        if (token) {
            headers.append('Authorization', `Bearer ${token}`);
        }

        return await fetch(`/api/v1/assets/balance/${userID}`, {headers: headers})
            .then(response => response.json())
    },
);


export const fetchProjectInfoAsync = createAsyncThunk(
    'assets/fetchProjectInfo',
    async () => await fetch('/api/v1/crypto/initial')
        .then(response => response.json()),
);
