import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {AuthPage} from "./pages/auth/Auth";
import {Portfolio} from "./pages/portfolio/Portfolio";
import {useAppSelector} from "./state/hooks";
import {selectAuth} from "./pages/auth/authSlice";
import {Loading} from "./components/loading/Loading";

const router = createBrowserRouter([
    {
        path: '/',
        element: <div className='middleScreen'><div>Under development</div></div>
    },
    {
        path: "/auth",
        element: <AuthPage/>,
    },
    {
        path: "/:userID",
        element: <div><Portfolio/></div>,
    },
]);

function App() {
    const {isInitialized} = useAppSelector(selectAuth);

    return (
        <div className='App'>
            {!isInitialized && <div className='middleScreen'>
                <Loading/>
            </div>}
            {isInitialized && <RouterProvider router={router}/>}
        </div>
    );
}

export default App;
