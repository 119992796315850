import {createSlice} from '@reduxjs/toolkit'
import {RootState} from "../../state/store";
import {fetchAssetsAsync, fetchChartAsync, fetchProjectInfoAsync} from "../../state/assetsApi";


export interface CryptoAsset {
    crypto_id: string,
    total_coins: number,
}


export interface Assets {
    price: number
    cost: number
    amount: number
    revenue: number
    crypto: { [key: string]: CryptoAsset }
    created_at: number
}



export interface AssetsState {
    initial: {
        start_date: number,
        initial_investment: number,
        donations: number,
    }
    current: Assets
    isLoading: boolean
    notFound: boolean
}

const initialState: AssetsState = {
    initial: {
        start_date: new Date(2022, 9, 11).getTime(),
        initial_investment: 0,
        donations: 0,
    },
    isLoading: true,
    notFound: false,
    current: {
        price: 0,
        cost: 0,
        amount: 0,
        revenue: 0,
        crypto: {},
        created_at: new Date().getTime(),
    }

}

export const assetsSlice = createSlice({
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssetsAsync.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAssetsAsync.fulfilled, (state: AssetsState , action: {payload: Assets}) => {
                state.isLoading = false;
                state.current = action.payload;
            })
            .addCase(fetchAssetsAsync.rejected, (state) => {
                state.isLoading = false;
                state.notFound = true;
                console.log("Failed to fetch assets");
            })
            .addCase(fetchChartAsync.rejected, (state) => {
                // state.status = 'failed';
            })
            .addCase(fetchProjectInfoAsync.fulfilled, (state: AssetsState , action) => {
                const projectInfo = action.payload;
                state.initial.start_date = new Date(projectInfo.start_date).getTime();
                state.initial.initial_investment = projectInfo.initial_investment;
                state.initial.donations = projectInfo.donations;
            })
    },
    name: 'assets',
    initialState: initialState
});

export const selectAssets = (state: RootState) => state.assets
export const assetsReducer =  assetsSlice.reducer
