import React from 'react';
import css from './Description.module.css';
import polkadot from '../../polkadot.svg';


export const Description: React.FC = () => {
    return <div>
        <div className={css.description}>
            Everybody knows that crypto <strong>winter is coming</strong>. So is it really a good time to invest in crypto?
            The sole goal of this project is to check how much money I can gain by investing in crypto during the next 24 months.
        </div>

        <div className={css.description}>
            <a className={css.polkadot} href='https://polkadot.network/' target='_blank' rel='noreferrer'>
                <img className={css.logo} src={polkadot} alt='Polkadot logo'/>
                <strong>Polkadot</strong>
            </a> is a blockchain network with proof-of-stake consensus. Stacking is an opportunity to earn passive income by
            locking your DOT tokens. The more tokens you lock, the more rewards you get. I'm in!
        </div>
        <div className={css.description}>
            This web application is a simple dashboard that shows the current value of my crypto portfolio.
            It uses different apis to fetch on-chain data and to calculate the value of my portfolio online.
        </div>
    </div>
}
