import React from 'react';

import css from './Donation.module.css'


export const Donation: React.FC = () => {
    return <div className={css.support}>
        <div>If you want to support this project, please consider donating to the following Polkadot address:</div>
        <div className={css.cryptoAddress}>
            <a href='https://explorer.polkascan.io/polkadot/account/14KYENwdv4hSEMZsdsovBZM3wgzx1kVG1kur19g1swA1dXjC'
               target='_blank' rel="noreferrer">
                13ipAeMeniAUtRNk43M3hNWaTufTDHWAEvPRVXyqvPV4FYK1
            </a>
        </div>
        <div>Community support may be optionally included in the results of this experiment.</div>
    </div>
}
