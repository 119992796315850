import React, {useEffect} from 'react';
import {selectAssets} from './assetsSlice';
import {useAppSelector} from '../../state/hooks';

import css from './Assets.module.css';
import {asCrypto, asEuro} from '../../utils/formatters';


const durationInDays: (start_date: Date, end_date: Date) => number =
    (start_date: Date, end_date: Date) => {
        return Math.floor((end_date.getTime() - start_date.getTime()) / (1000 * 3600 * 24))
    }


export const Assets: React.FC = () => {
    const assets = useAppSelector(selectAssets);
    const [isProfitable, setIsProfitable] = React.useState<boolean>(false);
    const [projectLength, setProjectLength] = React.useState<number>(0);
    const [projectStart, setProjectStart] = React.useState<string>('');


    useEffect(() => {
        setProjectStart(new Date(assets.initial.start_date).toLocaleDateString())

        const projectDuration = durationInDays(new Date(assets.initial.start_date), new Date());
        setProjectLength(projectDuration)

        setIsProfitable(assets.current.revenue >= 0)
    }, [assets])

    return (
        <div className={css.assets}>
            <div>Start date:</div>
            <div>{projectStart}</div>

            <div>Duration:</div>
            <div>{projectLength} days</div>

            <div>Investment:</div>
            <div>{asEuro(assets.current.cost)}</div>

            <div>
                <a className={css.coinbase}
                   href="https://www.coinbase.com/price/polkadot"
                   target="_blank"
                   rel="noreferrer">
                    Coinbase
                </a> price:
            </div>
            <div>{asEuro(assets.current.amount)}</div>

            <div>Crypto:</div>
            <div>{
                Object.values(assets.current.crypto)
                    .map((crypto, index) => <div key={index}>{asCrypto(crypto.total_coins, crypto.crypto_id)}</div>)
            }</div>

            {/*<div className={isCountDonations ? css.isPositive : css.isNegative}>*/}
            {/*    Donations*/}
            {/*    <input className={css.includeDonations} type="checkbox" onChange={handleChange}*/}
            {/*           checked={isCountDonations}/>*/}
            {/*    :*/}
            {/*</div>*/}
            {/*<div>{asDot(assets.initial.donations)}</div>*/}

            <div>Revenue:</div>
            <div className={isProfitable ? css.isPositive : css.isNegative}>{asEuro(assets.current.revenue)}</div>
        </div>
    );
}
